export default {
  namespaced: true,
  state: {
    ipas: [],
  },
  getters: {
    getIpas: state => state.ipas,
  },
  mutations: {
    setIpas(state, ipas) {
      state.ipas = ipas
    },
  },
}
