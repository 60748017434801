import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/calendar/Calendar.vue'),
    },
    {
      path: '/shared-profiles',
      name: 'shared-profiles',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Shared Profiles',
        breadcrumb: [
          {
            text: 'Shared Profiles',
            active: true,
          },
        ],
        requiresAuth: true,
      },
    },
    {
      path: '/two-factor-auth',
      name: 'two-factor-auth',
      component: () => import('@/views/TwoFactorAuth.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/forgotPassword',
      name: 'forgotPassword',
      component: () => import('@/views/Auth/ForgotPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/changeMyPassword',
      name: 'changeMyPassword',
      component: () => import('@/views/Auth/RecoverPassword.vue'),
      meta: {
        requiresAuth: true,
        layout: 'full',
      },
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      component: () => import('@/views/Auth/ResetPassword.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/system/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    // {
    //   path: '/faq',
    //   name: 'faq',
    //   component: () => import('@/views/faq/Faq.vue'),
    //   meta: {
    //     requiresAuth: true,
    //     pageTitle: 'FAQ',
    //     breadcrumb: [
    //       {
    //         text: 'Pages',
    //       },
    //       {
    //         text: 'FAQ',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: '*',
      redirect: 'error-404',
    },
    {
      path: '/',
      redirect: 'login',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen1
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  const { requiresAuth } = to.meta
  const isLoggedIn = store.getters['user-auth/isLoggedIn']
  const requireResetPassword = store.getters['user-auth/requireResetPassword']
  if (requiresAuth) { // if requiered auth
    if (!isLoggedIn) next('/login') // if is not logged redirect to login
    else if (requireResetPassword && to.name !== 'changeMyPassword') next('/changeMyPassword')
    else next()
  }
  next()
})

export default router
