export default {
  namespaced: true,
  state: {
    idAccessLevel: 0,
  },
  getters: {
    getIdAccessLevel: state => state.idAccessLevel,
  },
  mutations: {
    setIdAccessLevel(state, idAccessLevel) {
      state.idAccessLevel = idAccessLevel
    },
  },
}
