const catalogFormat = {}
const dictionary = {
  Site: 'Sites',
  HealthPlan: 'HP',
  Ipa: 'IPA',
  PrimaryCareProvider: 'PCP Enrollment',
  Provider: 'Providers',
  ProviderType: 'Provider Type',
  ProviderSite: 'Provider Site',
  ProviderSpeciality: 'Provider Speciality',
  VisitType: 'Visit Type',
  VisitTypeGroup: 'Visit Type Group',
  Source: 'Source',
  State: 'State',
  InteractionType: 'Interaction Type',
  CoverageType: 'Coverage',
  Gender: 'Genders',
  Speciality: 'Speciality',
  City: 'Cities',
}

function catalogDictionary(catalog) {
  const catalogf = dictionary[catalog]
  return catalogf
}
/* eslint-disable func-names */
catalogFormat.install = function (Vue) {
  Vue.filter('catalog-dictionary', val => catalogDictionary(val))
}
/* eslint-enable func-names */
export default catalogFormat
