import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
/* eslint-disable no-param-reassign */
const errorHandler = {

  install(Vue) {
    Vue.prototype.toastErrorHandler = error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Network Error',
            text: 'Your network connection is not available',
            icon: 'WifiOffIcon',
            variant: 'danger',
          },
        })
      } else {
        // Something happened in setting up the request that triggered an Error
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Somethig went wrong',
            text: 'Try Again later',
            icon: 'WifiOffIcon',
            variant: 'danger',
          },
        })
      }
    }
  },
}
/* eslint-enable no-param-reassign */
export default errorHandler
