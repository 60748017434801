import SecureStorage from 'secure-web-storage'

const CryptoJS = require('crypto-js')

// NOTE: Your Secret Key should be user inputed or obtained through a secure authenticated request.
//       Do NOT ship your Secret Key in your code.
const SECRET_KEY = 'nfkerjfbwlek'

/* eslint-disable no-param-reassign */
const secureStorage = new SecureStorage(sessionStorage, {
  hash: function hash(key) {
    key = CryptoJS.SHA256(key, SECRET_KEY)

    return key.toString()
  },
  encrypt: function encrypt(data) {
    data = CryptoJS.AES.encrypt(data, SECRET_KEY)

    data = data.toString()

    return data
  },
  decrypt: function decrypt(data) {
    data = CryptoJS.AES.decrypt(data, SECRET_KEY)

    data = data.toString(CryptoJS.enc.Utf8)

    return data
  },
})
/* eslint-enable no-param-reassign */

// there is no need to stringify/parse you objects before and after storing.
export default secureStorage
