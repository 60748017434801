import moment from 'moment'

const dateFormat = {}

function convertDateFormat(date) {
  const dateformated = moment(date).utc().format('MM-DD-YYYY')
  return dateformated
}
/* eslint-disable func-names */
dateFormat.install = function (Vue) {
  Vue.filter('date-format', val => convertDateFormat(val))
}
/* eslint-enable func-names */
export default dateFormat
