export default {
  namespaced: true,
  state: {
    idUserProfile: 0,
  },
  getters: {
    getIdUserProfile: state => state.idUserProfile,
  },
  mutations: {
    setIdUserProfile(state, idUserProfile) {
      state.idUserProfile = idUserProfile
    },
  },
}
